import { Button, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { MenuItem, Pagination, TextField } from "@mui/material";
import { useCatalog } from "context/catalog/CatalogContext";
import { useUi } from "context/ui/UIContext";
import {
  IExtractItem,
  IExtractParams,
  IWalletExtract,
  TagStatus,
  TransactionType,
} from "models/wallet/IWallet";
import { PrivateHeader } from "pages/private/Components/privateHeader/PrivateHeader";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { WalletApi } from "services/Api/wallet/WalletApi";
import Utils from "utils/utils";
import styles from "./Extract.module.scss";
import NumberToMoneyString from "utils/mask/NumberToMoney";
import { hideCPF } from "utils/mask/cpf";

type Period = "15" | "30" | "60" | "90";

interface ExtractFilter {
  startDate?: string;
  endDate?: string;
  page?: number;
  pageSize?: number;
}

export interface IExtract {}

const Extract: FC<IExtract> = ({}) => {
  const history = useHistory();
  const { catalog, onLoading: onLoadingCatalog } = useCatalog();
  const { showLoading, hideLoading, isMobile } = useUi();
  const { storeId, walletId } = useParams<{
    storeId: string;
    walletId: string;
  }>();

  const [period, setPeriod] = useState<Period | null>(null);
  const [extract, setExtract] = useState<IWalletExtract>();
  const [filter, setFilter] = useState<ExtractFilter>({});

  const firstLoad = useRef(true);

  const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (!!ev.target?.name && !!ev.target?.value) {
      const selectedDateTime = new Date(ev.target.value);
      const dateTimeInUTC = selectedDateTime.toISOString();
      setFilter((prev) => ({ ...prev, [ev.target?.name]: dateTimeInUTC }));
      setPeriod(null);
    }
  }, []);

  const getExtract = useCallback(
    (_filter: ExtractFilter) => {
      const walletApi = WalletApi();
      showLoading();
      const payload: IExtractParams = {
        LocalClientId: storeId,
        page: _filter.page ?? 0,
        pageSize: _filter.pageSize ?? 10,
        WalletId: walletId,
        endDate: _filter.endDate,
        startDate: _filter.startDate,
      };

      firstLoad.current = false;

      walletApi
        .getExtract(payload)
        .then((response) => {
          const TAG = response.data?.TAG;
          setExtract({
            ...response.data,
            TAG: response.data?.Status === TagStatus.Active ? TAG : "",
          });
        })
        .catch(() => {
          history.goBack();
        })
        .finally(() => {
          hideLoading();
        });
    },
    [hideLoading, history, showLoading, storeId, walletId]
  );

  useEffect(() => {
    if (firstLoad.current) {
      getExtract({});
    }
  }, [getExtract, hideLoading, history, showLoading, storeId, walletId]);

  const handlePagination = useCallback(
    (page: number) => {
      setFilter((prev) => {
        getExtract({ ...prev, page });
        return { ...prev, page };
      });
    },
    [getExtract]
  );

  const handleChangePageSize = useCallback(
    (ev: ChangeEvent<{ value: any }>) => {
      const pageSize = ev.target.value;
      setFilter((prev) => {
        getExtract({ ...prev, pageSize, page: 0 });
        return { ...prev, pageSize, page: 0 };
      });
    },
    [getExtract]
  );

  const handleChangePeriod = useCallback(
    (_period: Period) => {
      setPeriod(_period);
      const _endDate = new Date();
      const _startDate = new Date(
        new Date().setDate(_endDate.getDate() - Number(_period))
      );
      setFilter((prev) => {
        const newFilter = {
          ...prev,
          startDate: _startDate.toISOString(),
          endDate: _endDate.toISOString(),
        };
        getExtract(newFilter);
        return newFilter;
      });
    },
    [getExtract]
  );

  const handleFilter = useCallback(() => {
    getExtract(filter);
  }, [filter, getExtract]);

  const getTransactionType = useCallback((type: TransactionType) => {
    switch (type) {
      case TransactionType.Consumption:
        return "Consumo:";
      case TransactionType.Recharge:
        return "Recarga:";
      case TransactionType.Refund:
        return "Estorno:";
      case TransactionType.Transfer:
        return "Transferência:";
    }
  }, []);

  const getTransactionOrigin = useCallback((item: IExtractItem) => {
    switch (item.Type) {
      case TransactionType.Consumption:
        return `Local: ${item.LocalName}`;
      case TransactionType.Recharge:
        return `Local: ${item.LocalName}`;
      case TransactionType.Refund:
        return `Local: ${item.LocalName}`;
      case TransactionType.Transfer:
        return `${!item.DataTransfer?.IsOrigin ? "Origem" : "Destino"}: ${
          item?.DataTransfer?.Name ?? "-"
        } ${
          !!item?.DataTransfer?.Name
            ? item?.DataTransfer?.IsDependent
              ? "(Dependente)"
              : "(Titular)"
            : ""
        }`;
    }
  }, []);

  const getColor = useCallback((item: IExtractItem) => {
    if (
      item.Type === TransactionType.Recharge ||
      item.Type === TransactionType.Refund
    ) {
      return "#399C54";
    } else if (item.Type === TransactionType.Transfer) {
      if (!item.DataTransfer?.IsOrigin) {
        return "#399C54";
      } else {
        return "#F44336";
      }
    } else {
      return "#F44336";
    }
  }, []);

  const totalPages = useMemo(
    () =>
      Math.max(
        Math.ceil((extract?.TotalRecords ?? 1) / (filter?.pageSize ?? 10)),
        1
      ),
    [extract, filter]
  );

  return (
    <div id={styles.Extract}>
      <PrivateHeader
        onClickBack={history.goBack}
        title={onLoadingCatalog ? "Wallet" : catalog?.name ?? "Wallet"}
      />
      {!!extract && (
        <div className={styles.container}>
          <h1>Extrato</h1>

          <div className={styles.header}>
            <div>
              <p style={{ fontSize: isMobile ? 16 : undefined }}>
                {extract?.MeepUser?.UserName}&nbsp;
                <span>
                  {extract?.MeepUser?.UserName
                    ? extract?.IsDependent
                      ? "(Dependente)"
                      : "(Titular)"
                    : ""}
                </span>
              </p>
              <p style={{ fontSize: isMobile ? 16 : undefined }}>
                {extract?.MeepUser?.CPF && hideCPF(extract.MeepUser.CPF)}
              </p>
              <p style={{ fontSize: isMobile ? 16 : undefined }}>
                Tag atual:{" "}
                <b>
                  {extract?.TAG &&
                    extract.TAG.replace(
                      /([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g,
                      `$1-`
                    )}
                </b>
              </p>
            </div>
            <div>
              <p style={{ fontSize: isMobile ? 14 : undefined }}>Saldo atual</p>
              <p style={{ fontSize: isMobile ? 24 : undefined }}>
                {NumberToMoneyString(extract?.Balance)}
              </p>
            </div>
          </div>

          <div
            className={styles.filter}
            style={{ flexDirection: isMobile ? "column" : undefined }}
          >
            <div style={{ width: isMobile ? "100%" : undefined }}>
              <p>Período</p>
              <div>
                <div
                  onClick={
                    period === "15" ? undefined : () => handleChangePeriod("15")
                  }
                  className={
                    period === "15" ? styles.selected : styles.unselected
                  }
                >
                  15 dias
                </div>
                <div
                  onClick={
                    period === "30" ? undefined : () => handleChangePeriod("30")
                  }
                  className={
                    period === "30" ? styles.selected : styles.unselected
                  }
                >
                  30 dias
                </div>
                <div
                  onClick={
                    period === "60" ? undefined : () => handleChangePeriod("60")
                  }
                  className={
                    period === "60" ? styles.selected : styles.unselected
                  }
                >
                  60 dias
                </div>
                <div
                  onClick={
                    period === "90" ? undefined : () => handleChangePeriod("90")
                  }
                  className={
                    period === "90" ? styles.selected : styles.unselected
                  }
                >
                  90 dias
                </div>
              </div>
            </div>
            <div
              className={styles.input}
              style={{
                width: isMobile ? "100%" : undefined,
                margin: isMobile ? "8px 0" : undefined,
              }}
            >
              <span className={styles.required}>Inicio</span>
              <TextField
                size="small"
                InputProps={{
                  inputProps: {
                    style: {
                      color: filter?.startDate
                        ? "black"
                        : "var(--Neutral-Gray-700, #A2A2A2)",
                    },
                  },
                }}
                name="startDate"
                onChange={changeHandleDate}
                fullWidth
                value={
                  filter?.startDate && Utils.toInputDateString(filter.startDate)
                }
                type="datetime-local"
              />
            </div>
            <div
              className={styles.input}
              style={{
                width: isMobile ? "100%" : undefined,
                margin: isMobile ? "8px 0" : undefined,
              }}
            >
              <span className={styles.required}>Fim</span>
              <TextField
                size="small"
                variant="outlined"
                InputProps={{
                  inputProps: {
                    style: {
                      color: filter?.endDate
                        ? "black"
                        : "var(--Neutral-Gray-700, #A2A2A2)",
                    },
                  },
                }}
                name="endDate"
                onChange={changeHandleDate}
                fullWidth
                value={
                  filter?.endDate && Utils.toInputDateString(filter.endDate)
                }
                type="datetime-local"
              />
            </div>
            <div
              style={{
                width: isMobile ? "100%" : undefined,
                margin: isMobile ? "8px 0" : undefined,
              }}
            >
              <Button
                variant="contained"
                fullWidth={isMobile}
                onClick={handleFilter}
              >
                Buscar
              </Button>
            </div>
          </div>

          <div className={styles.content}>
            {extract.Records.map((it, key) => (
              <div key={key}>
                <p style={{ fontSize: isMobile ? 16 : undefined }}>
                  {Utils.toDateAndTime(it.Date)}
                </p>
                <p style={{ fontSize: isMobile ? 16 : undefined }}>
                  {getTransactionOrigin(it)}
                </p>
                <div>
                  <span
                    style={{
                      fontSize: isMobile ? 16 : undefined,
                      color: getColor(it),
                    }}
                  >
                    {getTransactionType(it.Type)}
                  </span>
                  <span
                    style={{
                      fontSize: isMobile ? 16 : undefined,
                      color: getColor(it),
                    }}
                  >
                    {NumberToMoneyString(it.Value)}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div>
            <div>
              <span>Exibir</span>
              <Select
                variant="outlined"
                value={filter?.pageSize ?? 10}
                onChange={handleChangePageSize}
                IconComponent={ExpandMore}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </div>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={totalPages}
              page={(filter?.page ?? 0) + 1}
              disabled={totalPages <= 1}
              onChange={(_, page) => handlePagination(page - 1)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Extract;
