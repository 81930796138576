import { Button, IconButton, Modal, Paper, Tooltip } from "@material-ui/core";
import {
  AddCircle,
  AttachMoney,
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
} from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import { ReactComponent as Alert } from "assets/icons/alert.svg";
import { ReactComponent as Contract } from "assets/icons/contract.svg";
import { ReactComponent as Empty } from "assets/image/empty.svg";
import { useCatalog } from "context/catalog/CatalogContext";
import { useUi } from "context/ui/UIContext";
import {
  IWalletDependents,
  NewDependentRequest,
  RemoveDependentRequest,
  TagStatus,
} from "models/wallet/IWallet";
import { PrivateHeader } from "pages/private/Components/privateHeader/PrivateHeader";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WalletApi } from "services/Api/wallet/WalletApi";
import NumberToMoneyString from "utils/mask/NumberToMoney";
import NewTagForm from "../../tag/components/newTagForm/NewTagForm";
import Tag from "../../tag/Tag";
import styles from "./Dependents.module.scss";

export interface IDependents {}

const Dependents: FC<IDependents> = ({}) => {
  const { walletId, storeId, url } = useParams<{
    storeId: string;
    walletId: string;
    url: string;
  }>();
  const { isMobile, toast, showLoading, hideLoading } = useUi();
  const { catalog } = useCatalog();
  const history = useHistory();

  const [onLoading, setOnLoading] = useState(false);
  const [dependents, setDependents] = useState<IWalletDependents[] | null>(
    null
  );
  const [dependentToEdit, setDependentToEdit] =
    useState<IWalletDependents | null>(null);
  const [showAddDependent, setShowAddDependent] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dependentToDelete, setDependentToDelete] =
    useState<IWalletDependents | null>(null);

  const getDependents = useCallback(async () => {
    const walletApi = WalletApi();
    setOnLoading(true);
    walletApi
      .getDependents(walletId)
      .then((response) => {
        setDependents(response.data);
      })
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        setOnLoading(false);
      });
  }, [history, walletId]);

  useEffect(() => {
    getDependents();
  }, [getDependents]);

  const handleSubmitNewTag = useCallback(
    (password: string, tag: string, closeModal: () => void, name?: string) => {
      const walletApi = WalletApi();
      showLoading();
      const params: NewDependentRequest = {
        CardName: name!,
        Password: password,
        TAG: tag,
        LocalId: storeId,
        WalletId: walletId,
      };
      walletApi
        .addDependent(params)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            setShowAddDependent(false);
            toast("Tag vinculada com sucesso", "success");
            getDependents();
          }
        })
        .finally(() => {
          hideLoading();
        });
    },
    [getDependents, hideLoading, showLoading, storeId, toast, walletId]
  );

  const handleEditDependent = useCallback((edit: IWalletDependents) => {
    setDependentToEdit(edit);
  }, []);

  const closeModal = useCallback(() => {
    setDependentToDelete(null);
    setShowDeleteModal(false);
  }, []);

  const handleDelete = useCallback((dependent: IWalletDependents) => {
    setDependentToDelete(dependent);
    setShowDeleteModal(true);
  }, []);

  const onConfirmDelete = useCallback(() => {
    const walletApi = WalletApi();
    showLoading();
    const params: RemoveDependentRequest = {
      LocalId: storeId,
      WalletId: walletId,
      UserId: dependentToDelete?.Id ?? "",
      TAG: dependentToDelete?.TAG ?? "",
    };
    walletApi
      .removeDependent(params)
      .then((response) => {
        if (response.status === 200) {
          closeModal();
          setShowDeleteModal(false);
          toast("Dependente removido com sucesso", "success");
          getDependents();
        }
      })
      .finally(() => {
        hideLoading();
      });
  }, [
    closeModal,
    dependentToDelete,
    getDependents,
    hideLoading,
    showLoading,
    storeId,
    toast,
    walletId,
  ]);

  const goToRecharge = useCallback(
    (walletId: string) => {
      history.push(`/${url}/${storeId}/wallet/recharge/${walletId}`);
    },
    [history, storeId, url]
  );

  const goToExtract = useCallback(
    (walletId: string) => {
      history.push(`/${url}/${storeId}/wallet/extract/${walletId}`);
    },
    [history, storeId, url]
  );

  return (
    <div id={styles.Dependents}>
      <PrivateHeader
        onClickBack={history.goBack}
        title={onLoading ? "Wallet" : catalog?.name ?? "Wallet"}
      />
      <div className={styles.container}>
        <div>
          <span>Dependentes</span>
          <Button variant="contained" onClick={() => setShowAddDependent(true)}>
            Adicionar dependente
            <AddCircle />
          </Button>
        </div>

        <div>
          {onLoading ? (
            <Skeleton
              width={"100%"}
              height={115}
              variant="rectangular"
              style={{ borderRadius: 4 }}
            />
          ) : dependents?.length ? (
            dependents.map((it, key) => (
              <div key={key} className={styles.listItem}>
                <div>
                  <span>{it.Name}</span>
                  <div>
                    <Tooltip title="Recarregar">
                      <IconButton onClick={() => goToRecharge(it.Id)}>
                        <AttachMoney />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Extrato">
                      <IconButton>
                        <Contract onClick={() => goToExtract(it.Id)} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Editar Dependente">
                      <IconButton onClick={() => handleEditDependent(it)}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar">
                      <IconButton onClick={() => handleDelete(it)}>
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <p>{`Tag atual: ${
                  it?.TAG &&
                  it.TAG.length === 8 &&
                  it.Status === TagStatus.Active
                    ? it.TAG.replace(/([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g, `$1-`)
                    : "Sem Tag"
                }`}</p>
                <h5>{`${NumberToMoneyString(it.Balance)}`}</h5>
              </div>
            ))
          ) : (
            <div className={styles.emptyList}>
              <Empty />
              <p>Nenhum dependente encontrado</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={showAddDependent}
        onClose={() => setShowAddDependent(false)}
        className={styles.newDependentModal}
      >
        <NewTagForm
          title="Novo dependente"
          buttonTitle="Concluir"
          nameInput
          onCancel={() => setShowAddDependent(false)}
          handleSubmitNewTag={handleSubmitNewTag}
        />
      </Modal>
      <Modal
        open={!!dependentToEdit}
        onClose={() => setDependentToEdit(null)}
        className={styles.tagModal}
      >
        <Tag
          storeId={storeId}
          onCancel={() => setDependentToEdit(null)}
          tag={
            dependentToEdit?.Status === TagStatus.Active
              ? dependentToEdit?.TAG
              : undefined
          }
          walletId={dependentToEdit?.Id ?? ""}
          dependent={dependentToEdit}
          cardName={dependentToEdit?.Name ?? ""}
          refresh={getDependents}
        />
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={closeModal}
        className={styles.modal}
      >
        <Paper className={styles.paper}>
          <div>
            <span>{`Excluir dependente`}</span>
            <IconButton onClick={closeModal}>
              <CloseOutlined />
            </IconButton>
          </div>
          <Alert />
          <p>{`Deseja reamente excluir o dependente`}</p>
          <Button variant="contained" color="inherit" onClick={onConfirmDelete}>
            Excluir
          </Button>
          <Button variant="outlined" color="inherit" onClick={closeModal}>
            Cancelar
          </Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default Dependents;
