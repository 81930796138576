import {
  EditUserRequest,
  CreditRechargeRequest,
  CreditRechargeResponse,
  IUserProfile,
  IUserWallet,
  IWalletDependents,
  LinkTagRequest,
  LinkTagResponse,
  NewDependentRequest,
  NewDependentResponse,
  PixRechargeRequest,
  PixRechargeResponse,
  PixStatusResponse,
  IExtractParams,
  IWalletExtract,
  RemoveDependentRequest,
  TransferBalanceRequest,
} from "models/wallet/IWallet";
import { Api } from "../Api";
import { AxiosResponse } from "axios";

export interface IWalletService {
  getWallet(localId: string): Promise<AxiosResponse<IUserWallet>>;
  getProfile(): Promise<AxiosResponse<IUserProfile>>;
  linkTag(params: LinkTagRequest): Promise<AxiosResponse<LinkTagResponse>>;
  deactiveTAG: (tag: string) => Promise<AxiosResponse<void>>;
  editUser(params: EditUserRequest): Promise<AxiosResponse<void>>;
  getDependents(walletId: string): Promise<AxiosResponse<IWalletDependents[]>>;
  addDependent(
    params: NewDependentRequest
  ): Promise<AxiosResponse<NewDependentResponse>>;
  creditRecharge: (
    params: CreditRechargeRequest
  ) => Promise<AxiosResponse<CreditRechargeResponse>>;
  createPixRecharge: (
    params: PixRechargeRequest
  ) => Promise<AxiosResponse<PixRechargeResponse>>;
  getPixStatus(id: string): Promise<AxiosResponse<PixStatusResponse>>;
  getExtract: (
    params: IExtractParams
  ) => Promise<AxiosResponse<IWalletExtract>>;
  removeDependent: (
    params: RemoveDependentRequest
  ) => Promise<AxiosResponse<void>>;
  transferBalance: (
    params: TransferBalanceRequest
  ) => Promise<AxiosResponse<void>>;
}

export const WalletApi = (): IWalletService => {
  const api = Api();

  const getWallet = async (
    localId: string
  ): Promise<AxiosResponse<IUserWallet>> => {
    const response = await api.get<IUserWallet>(
      `EstablishmentWallet/ByLocalId/${localId}`
    );

    return response;
  };

  const getProfile = async (): Promise<AxiosResponse<IUserProfile>> => {
    const response = await api.get<IUserProfile>(`EstablishmentWallet/Profile`);
    return response;
  };

  const getDependents = async (
    WalletId: string
  ): Promise<AxiosResponse<IWalletDependents[]>> => {
    const response = await api.get<IWalletDependents[]>(
      `EstablishmentWallet/Dependents`,
      { params: { WalletId } }
    );

    return response;
  };

  const linkTag = async (
    params: LinkTagRequest
  ): Promise<AxiosResponse<LinkTagResponse>> => {
    const response = await api.put(
      "EstablishmentWallet/LinkCashlessCard",
      params
    );

    return response;
  };

  const addDependent = async (
    params: NewDependentRequest
  ): Promise<AxiosResponse<NewDependentResponse>> => {
    const response = await api.post("EstablishmentWallet/Dependent", params);

    return response;
  };

  const removeDependent = async (
    params: RemoveDependentRequest
  ): Promise<AxiosResponse<void>> => {
    const response = await api.post(
      "EstablishmentWallet/Dependent/Remove",
      params
    );

    return response;
  };

  const editUser = async (
    params: EditUserRequest
  ): Promise<AxiosResponse<void>> => {
    const response = await api.put("EstablishmentWallet/WalletCard", params);

    return response;
  };

  const deactiveTAG = async (TAG: string) => {
    return await api.put<void>("EstablishmentWallet/WalletCard/ChangeStatus", {
      TAG,
      status: 0,
    });
  };

  const creditRecharge = async (
    params: CreditRechargeRequest
  ): Promise<AxiosResponse<CreditRechargeResponse>> => {
    const response = await api.post(
      "EstablishmentWallet/RechargeByCreditCard",
      params
    );

    return response;
  };

  const createPixRecharge = async (
    params: PixRechargeRequest
  ): Promise<AxiosResponse<PixRechargeResponse>> => {
    const response = await api.post(
      "EstablishmentWallet/RechargeByPix",
      params
    );

    return response;
  };

  const getPixStatus = async (
    id: string
  ): Promise<AxiosResponse<PixStatusResponse>> => {
    const response = await api.get<PixStatusResponse>(
      `EstablishmentWallet/PixOrder/${id}`
    );
    return response;
  };

  const getExtract = async (
    _params: IExtractParams
  ): Promise<AxiosResponse<IWalletExtract>> => {
    const params = {
      ..._params,
      hidePendingOrders: true,
    };
    const response = await api.get<IWalletExtract>(
      `EstablishmentWallet/Extract`,
      { params }
    );

    return response;
  };

  const transferBalance = async (
    params: TransferBalanceRequest
  ): Promise<AxiosResponse<void>> => {
    const response = await api.post(
      "EstablishmentWallet/Dependent/BalanceTransfer",
      params
    );

    return response;
  };

  return {
    getWallet,
    getProfile,
    linkTag,
    deactiveTAG,
    editUser,
    getDependents,
    addDependent,
    creditRecharge,
    createPixRecharge,
    getPixStatus,
    getExtract,
    removeDependent,
    transferBalance,
  };
};
