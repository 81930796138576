import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { useUi } from "context/ui/UIContext";
import {
  IUserWallet,
  IWalletDependents,
  TransferBalanceRequest,
} from "models/wallet/IWallet";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { WalletApi } from "services/Api/wallet/WalletApi";
import NumberToMoneyString from "utils/mask/NumberToMoney";
import styles from "./Transfer.module.scss";

interface ISelectUser {
  Name: string;
  Id: string;
  Balance: number;
  IsDependent: boolean;
}

export interface ITransfer {
  onCancel: () => void;
  refresh?: () => Promise<void>;
  wallet: IUserWallet | null;
  localId: string;
}

const Transfer: FC<ITransfer> = ({ onCancel, refresh, wallet, localId }) => {
  const valueRef = useRef<HTMLInputElement>(null);
  const { showLoading, hideLoading, toast } = useUi();

  const [onLoading, setOnLoading] = useState(false);
  const [transferValue, setTransferValue] = useState("");
  const [origin, setOrigin] = useState("");
  const [destiny, setDestiny] = useState("");
  const [dependents, setDependents] = useState<IWalletDependents[] | null>(
    null
  );

  const firstLoad = useRef(true);

  const getDependents = useCallback(async () => {
    const walletApi = WalletApi();
    setOnLoading(true);
    firstLoad.current = false;
    walletApi
      .getDependents(wallet?.Id ?? "")
      .then((response) => {
        setDependents(response.data);
      })
      .finally(() => {
        setOnLoading(false);
      });
  }, [wallet]);

  useEffect(() => {
    if (firstLoad.current) {
      getDependents();
    }
  }, [getDependents]);

  const { selectList, maxValue } = useMemo(() => {
    let selectList: ISelectUser[] = [];
    if (wallet && dependents) {
      selectList = [
        {
          Balance: wallet.Balance,
          Id: wallet.Id,
          Name: wallet.UserName,
          IsDependent: false,
        },
      ];

      dependents.forEach((it) => {
        selectList.push({
          Balance: it.Balance,
          Id: it.Id,
          Name: it.Name,
          IsDependent: true,
        });
      });
    }
    const maxValue = selectList.find((it) => it.Id === origin)?.Balance;
    return { selectList, maxValue };
  }, [dependents, origin, wallet]);

  const inputMoneyMask = useCallback(
    (value?: string) => {
      if (!value) {
        return undefined;
      }
      value = value.replace(/\D/g, "");
      while (value.length < 3) {
        value = "0" + value;
      }
      value = value.replace(/^0+(?=\d{3,})/, "");
      const formatWithThousandSeparator = (val: string) => {
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      };
      const maskedValue =
        "R$ " +
        formatWithThousandSeparator(value.slice(0, -2)) +
        "," +
        value.slice(-2);

      if (value === "000") {
        if (valueRef && valueRef.current) {
          valueRef.current.value = "";
        }
        return undefined;
      }
      return maskedValue;
    },
    [valueRef]
  );

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.slice(0, -2) + "," + value.slice(-2);
      value = value.split(",")[0].length === 0 ? "0" + value : value;

      const price = parseFloat(value.replace(",", "."));

      if (maxValue && price > maxValue) {
        setTransferValue(
          maxValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          })
        );
      } else {
        setTransferValue(price === 0 ? "" : value);
      }
    },
    [maxValue]
  );

  const handleChangeOrigin = useCallback((ev: ChangeEvent<{ value: any }>) => {
    const _origin = ev.target.value;
    setOrigin(_origin);
    setTransferValue("");
    if (valueRef && valueRef.current) {
      valueRef.current.value = "";
    }
  }, []);

  const handleChangeDestiny = useCallback((ev: ChangeEvent<{ value: any }>) => {
    const _destiny = ev.target.value;
    setDestiny(_destiny);
  }, []);

  const handleSubmitTransfer = useCallback(() => {
    const walletApi = WalletApi();
    showLoading();
    const params: TransferBalanceRequest = {
      DestinationWalletId: destiny,
      LocalId: localId,
      OriginWalletId: origin,
      TransferBalance: parseFloat(transferValue.replace(",", ".")),
    };
    walletApi
      .transferBalance(params)
      .then((response) => {
        if (response.status === 200) {
          refresh && refresh();
          onCancel();
          toast("Transferência realizada com sucesso", "success");
        }
      })
      .finally(() => {
        hideLoading();
      });
  }, [
    destiny,
    hideLoading,
    localId,
    onCancel,
    origin,
    refresh,
    showLoading,
    toast,
    transferValue,
  ]);

  const disableSubmit = useMemo(
    () => !origin || !destiny || !transferValue,
    [destiny, origin, transferValue]
  );

  return (
    <div id={styles.Transfer}>
      <h4>
        Transferência de saldo
        <IconButton onClick={onCancel}>
          <CloseOutlined />
        </IconButton>
      </h4>

      <div className={styles.input}>
        <p>
          De <span>*</span>
        </p>
        <Select variant="outlined" value={origin} onChange={handleChangeOrigin}>
          {selectList.map((it, key) => (
            <MenuItem
              value={it.Id}
              key={key}
              disabled={it.Id === destiny || it.Balance === 0}
            >{`${it.Name} • ${NumberToMoneyString(it.Balance)} (${
              it.IsDependent ? "Dependente" : "Titular"
            })`}</MenuItem>
          ))}
        </Select>
      </div>

      <div className={styles.input}>
        <p>
          Para <span>*</span>
        </p>
        <Select
          variant="outlined"
          value={destiny}
          onChange={handleChangeDestiny}
        >
          {selectList.map((it, key) => (
            <MenuItem value={it.Id} key={key} disabled={it.Id === origin}>{`${
              it.Name
            } • ${NumberToMoneyString(it.Balance)} (${
              it.IsDependent ? "Dependente" : "Titular"
            })`}</MenuItem>
          ))}
        </Select>
      </div>

      <div className={styles.transfer}>
        <p>Valor da transferência</p>
        <TextField
          inputRef={valueRef}
          size="small"
          placeholder={"R$ 0,00"}
          value={inputMoneyMask(transferValue)}
          variant="standard"
          name={"value"}
          onChange={handleChangeValue}
        />
      </div>

      <Button
        fullWidth
        variant="contained"
        disabled={disableSubmit}
        onClick={handleSubmitTransfer}
      >
        Transferir
      </Button>
      <Button fullWidth variant="outlined" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default Transfer;
